@import '../../scss/variables.scss';

.landing-page-container {
  .hero-section {
    min-height: 100vh;
    background-color: $brandPrimaryColor;
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    justify-content: space-between;

    .sign-in {
      align-self: flex-end;
      margin: 50px 50px 0 0;
    }

    .sign-in-button {
      height: 50px;
      width: 130px;
      font-weight: 200;
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        background: $brandPrimaryColorDark;
      }
    }

    @media screen and (max-width: 576px) {
      .sign-in {
        width: 100%;
        margin: 0;
      }
      .sign-in-button {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #fff;
      }
    }

    .hero-heading-container {
      max-width: 600px;
      align-self: center;
      margin-top: 100px;

      .hero-heading {
        font-size: 36px;
        font-weight: 200;
        max-width: 600px;
        line-height: 38px;
        text-align: center;
      }

      @media screen and (max-width: 576px) {
        .hero-heading {
          font-size: 27px;
          line-height: 29px;
        }
      }
    }

    .logo-container {
      width: 150px;
      margin: 0 auto 40px;

      .logo-image {
        margin-left: 9px;
        width: 100%;
      }
    }

    .create-account {
      padding: 0 15px;
      width: 100%;
      display: flex;
    }

    .create-account-button {
      height: 50px;
      max-width: 373px;
      font-weight: 200;
      flex-grow: 1;
      margin: 0 auto;
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        background: $brandPrimaryColorDark;
      }
    }

    .store-icon-container {
      width: 200px;

      &.apple {
        padding: 13px 14px 14px 14px;
        margin-left: -1px;
      }
    }

    @media screen and (max-width: 576px) {
      .store-icon-container {
        width: 175px;
      }
    }

    .app-store-icon {
      width: 100%;
    }

    .already-have-account {
      color: #fff;
      font-size: 13px;
      font-weight: 200;
      align-self: center;

      &:hover {
        color: $brandPrimaryColorExtraLight;
      }
    }

    .navigate-down-link {
      align-self: center;
      padding-bottom: 15px;
    }

    .navigate-down {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: unset;
      background: $brandPrimaryColorLight;
      align-self: center;

      &:hover {
        color: $brandPrimaryColorDark;
      }
    }
  }

  .screenshot-container {
    max-width: 120px;
    margin: 0 auto;

    .screenshot-image {
      width: 100%;
    }
  }

  .sticky-header {
    height: 100px;
    background-color: #fff;
    position: sticky;
    top: 0;
    display: flex;
    border-bottom: 1px solid #ccc;

    .logo-text {
      font-weight: 200;
      font-size: 25px;
      font-family: 'Montserrat', sans-serif;
      color: $brandPrimaryColor;
      align-self: center;
      margin: auto;
    }

    .join-button {
      color: $brandPrimaryColor;
      position: absolute;
      right: 25px;
      top: 25px;
      border: 1px solid $brandPrimaryColor;
      width: 150px;

      &:hover {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .sticky-header {
      height: 65px;

      .logo-text {
        font-size: 25px;
      }

      .join-button {
        color: $brandPrimaryColor;
        position: absolute;
        right: 10px;
        top: 10px;
        border: 1px solid $brandPrimaryColor;
        width: 100px;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .feature-split-container {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  @media screen and (max-width: 576px) {
    .feature-split-container {
      flex-direction: column;
    }
  }

  .feature-card {
    margin: 0;
    border-bottom: 1px solid #ccc;
    padding: 50px 15px;
    background: #fff;
    display: flex;

    &.no-background {
      background: unset;
      color: #fff;
    }
  }

  .horizontal {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .vertical {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
  }

  .feature-header {
    font-weight: 200;
    margin-bottom: 20px;
  }

  .feature-description {
    font-weight: 200;
  }

  .icon-container {
    margin: 15px 50px;
  }

  .copyright {
    margin: 5px;
    text-align: center;
    color: #ccc;
    font-size: 13px;
  }
}
