@import '../../../scss/variables.scss';
@import '../../../scss/styles.scss';

.question-card-container {
  z-index: 1;
  .date-container {
    color: #999;
    font-size: 12px;
    margin: 10px 0 5px 0;
    text-align: center;
  }

  .response-container {
    background: #fff;
    color: #555;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto 15px auto;
    padding: 0 15px 15px 15px;
    position: relative;
  }

  @media screen and (max-width: 768px) {
    .response-container {
      border-radius: 0;
    }
  }

  .edit-button {
    position: absolute;
    top: 2px;
    right: 5px;
    color: $brandPrimaryColor;
    font-size: 21px;

    &:hover {
      color: $brandPrimaryColorDark;
    }
  }

  .header {
    border-bottom: 1px solid #888;
    margin: 0 -15px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    background: #fff;
    color: #555;
    border-radius: 10px 10px 0 0;

    .title-container {
      margin-left: 15px;
      flex-direction: column;
    }

    p {
      text-align: center;
      font-size: 15px;
      margin: 0;
      padding: 0;
    }
  }

  .question-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0 10px;
  }

  p {
    font-size: 16px;
  }

  .icon-container {
    font-size: 30px;
    margin-right: 10px;
    color: #555;
    font-weight: 700;
  }

  .child-response-container {
    background: #fff;
    margin: 10px 0;
    border-top: 1px solid #ccc;
  }

  .response-quote-container {
    display: flex;
  }

  .callout {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #1caece;
    margin: -1px 15px 0 auto;
  }

  .timestamp {
    font-size: 10px;
    color: #555;
    width: 100px;
    display: flex;
    background: #fff;
    margin: -9px auto 0 auto;
    justify-content: center;
  }

  .parent-comments {
    color: #000;
    background: #ddd;
    font-size: 12px;
    margin-top: 5px;
    padding: 10px;
  }

  .response-quote {
    color: #fff;
    border-radius: 20px;
    margin-top: 15px;
    padding: 5px 10px;
    font-size: 15px;
    font-weight: 400;
    margin-left: auto;
  }

  .parent-comments-container {
    display: flex;
    justify-content: center;
  }

  .author-name {
    text-align: right;
    font-size: 15px;
    margin: 0;

    .author-date {
      font-size: 12px;
      color: #888;
    }

    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  .my-notes {
    color: #555;
  }
}
