@import '../../../scss/variables.scss';

.description-container {
  max-width: 800px;

  .icon-container {
    margin: 15px 0 15px 50px;

    flex-direction: column;
  }

  @media screen and (max-width: 576px) {
    .icon-container {
      margin: 30px auto 0 !important;
    }
  }

  .icon-upper-container {
    width: 200px;
    margin: auto;

    .icon {
      width: 100%;
    }
  }

  .icon-lower-container {
    width: 200px;
    margin: auto;

    .icon {
      width: 100%;
    }
  }

  .organize-icon {
    font-size: 100px;
    color: $brandPrimaryColor;
  }
}
