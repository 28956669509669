@import '../../scss/variables.scss';
@import '../../scss/styles';

.my-children-root {
  background: #fff;
}

.my-children-container {
  background: #fff;
  padding: 100px 0;
  text-align: center;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .btn-dark {
    background: unset;
    border: unset;

    &:hover {
      background: unset;
      color: $brandPrimaryColorExtraLight;
    }
  }

  .welcome-header {
    font-weight: 200;
  }

  .children-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .child-container {
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000;
    font-weight: 200;
    flex-grow: 1;
    padding: 0 0 10px 0;
    margin: 10px 10px;
    background: #ddd;
    border-radius: 4px;

    .edit-button-container {
      border-radius: 10px 10px 0 0;
      display: flex;
      background: #ddd;
    }

    .edit-button {
      color: $brandPrimaryColor;
      margin: 0 0 0 auto;
      font-size: 21px;
    }

    .menu-icon {
      &:hover {
        color: $brandPrimaryColorDark;
      }
    }

    .child-details-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .child-icon {
        margin-top: -30px;
        width: 100px;
      }

      .child-details {
        margin: -25px 0 5px 30px;
        text-align: left;
      }
    }

    .child-name {
      font-size: 25px;
      margin: 0;
    }

    .child-age {
      font-size: 15px;
      margin: 5px 0;
    }

    .child-entries {
      font-size: 15px;
      margin: 5px 0;
    }
  }

  .add-child-button {
    margin: 25px auto;
  }

  .view-quotes-container {
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;

    .view-quotes {
      font-size: 16px;
      font-weight: 600;
    }
    .forward-icon {
      margin-left: 10px;
    }
  }

  .delete-icon {
    color: #c00;
  }
}
