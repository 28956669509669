@import '../../scss/styles.scss';

.new-account-container {
  max-width: 430px;
  margin: auto;

  .new-account-card {
    background: #fff;
    margin-top: 50px;
    border: 1px solid #888;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: 15px;
  }

  .new-account-header {
    font-size: 17px;
    align-content: center;
    align-self: center;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
  }

  .submit {
    margin: 15px 0;
  }

  .error-text {
    color: #f50057;
    font-size: 12px;
    margin: 5px 0 0 0;
  }

  .divider {
    display: flex;
    margin-top: 10px;
  }

  .divider-line {
    border-top: 1px solid #888;
    flex-grow: 1;
  }

  .divider-text {
    align-content: center;
    padding: 0 15px;
    background: #fff;
    margin-top: -12px;
    font-weight: 700;
  }

  .sign-in-google-content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .google-logo {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }
  }

  .sign-in-google {
    margin-bottom: 15px;
  }

  .already-have-account {
    background: #eee;
    margin: 0 -15px -15px -15px;
    padding: 15px 15px;
    border-top: 1px solid #888;
    border-radius: 0 0 5px 5px;
    text-align: center;

    a {
      font-weight: 600;
    }
  }
}
