@import '../../scss/variables.scss';

.navbar-sv-container {
  background: #fff;
  display: flex;
  border-bottom: 1px solid #888;
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 2;

  .logo-button {
    background: transparent;
    border: none;
    color: $brandPrimaryColor;
    align-self: center;
    margin: 1px auto 0;
    padding-right: 55px;
    font-size: 25px;
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
  }

  .hamburger-button {
    margin: 0 0 0 12px;
    padding: 0;
    align-self: center;
    background: transparent;
    border: none;
    font-size: 29px;
    color: #555;
    width: 40px;
    height: 40px;
    z-index: 1;

    &:hover {
      color: $brandPrimaryColor;
    }
  }

  .add-entry {
    position: absolute;
    top: 5px;
    right: 10px;
    font-weight: 200;
    height: 40px;
    width: 40px;
    line-height: 0;
    font-size: 32px;
    z-index: 1;

    span {
      position: fixed;
      top: 26px;
      right: 20px;
    }
  }
}
