@import '../../scss/variables.scss';

.sv-menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $appBackground;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .close-button {
    color: #fff;
    background: transparent;
    border: none;
    position: absolute;
    top: 3px;
    left: 16px;
    font-size: 32px;
    padding: 0;
  }

  .icon-container {
    margin: 0 auto;
    width: 100px;

    .icon {
      width: 100%;
    }
  }

  .logo-text {
    color: #fff;
    font-size: 25px;
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .links-container {
    margin: 0;

    ul {
      list-style-type: none;
      padding: 0 15px;
      margin: 0;
      background: $appBackgroundLight;
    }

    .list-item {
      font-size: 17px;
      font-weight: 200;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #aaa;

      &.last {
        border-bottom: none;
      }
    }

    .item-text {
      margin-left: 10px;
    }

    .chevron-right {
      margin-left: auto;
    }
  }

  .sign-out {
    margin: 0 15px;
    color: #fff;
    border: 1px solid #fff;
  }
}
