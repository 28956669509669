@import './variables.scss';

body {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 100;
  background: $appBackground;
  min-width: 375px;
}

input {
  font-size: 16px !important;
}

.ui.form textarea {
  font-size: 16px !important;
  resize: none;
}

a {
  color: $brandPrimaryColor;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $brandPrimaryColorDark;
    text-decoration: none;
  }
}

.modal-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: #000;
  opacity: 0.7;
  top: 0;
  min-height: 100vh;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  padding: 10px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 101;

  .modal {
    background: #fff;
    position: relative;
    display: flex;
    margin: auto;
    opacity: 1;
    z-index: 101;
    flex-direction: column;
    margin: 15px;
    max-width: 500px;
    max-height: 500px;
    padding: 15px;
    border: 1px solid #888;
    border-radius: 10px;
  }
}

.text-input {
  height: 39px;
  font-size: 13px;
  border: 1px solid #888;
  border-radius: 0.25rem;
  padding: 0 10px;
}

.text-input-label {
  font-size: 13px;
  font-weight: 500;
  margin: 07px 0 2px 0;
}

// Bootstrap overrides for Button
.btn {
  height: 45px;
  font-size: 15px;
  font-weight: 600 !important;
  font-family: 'Montserrat', sans-serif;
}

.btn-primary {
  background-color: $brandPrimaryColor;
  border-color: $brandPrimaryColor;
  color: white;

  &:hover {
    background-color: $brandPrimaryColorDark;
  }
}

.btn-outline-primary {
  border-color: $brandPrimaryColor;
  color: $brandPrimaryColor;

  &:hover {
    background-color: $brandPrimaryColorDark;
    color: white;
  }
}

.btn-outline-secondary {
  border-color: #fff;
  color: #fff;

  &:hover {
    background-color: $brandPrimaryColor;
    color: white;
  }
}

.btn-link {
  color: $brandPrimaryColor;

  &:hover {
    color: $brandPrimaryColorDark;
    text-decoration: none;
  }
}

// Bootstrap overrides for navbar
.bg-light {
  background-color: #fff !important;
}

.navbar-brand {
  color: $brandPrimaryColorDark !important;
}

.navbar {
  a {
    color: #fff;
  }

  .dropdown-menu {
    left: unset;
    right: 0;

    .dropdown-item {
      &:active {
        background-color: $brandPrimaryColor;
        color: white;
      }
    }

    a {
      color: #555;
    }
  }
}

.back-link {
  color: #fff;
  margin: 15px;
  display: flex;
  align-items: center;

  &:hover {
    color: $brandPrimaryColorExtraLight;
  }

  .back-icon {
    margin-right: 10px;
  }
}

.fade-in {
  -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.25s; /* Firefox < 16 */
  -ms-animation: fadein 0.25s; /* Internet Explorer */
  -o-animation: fadein 0.25s; /* Opera < 12.1 */
  animation: fadein 0.25s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-bg {
  -webkit-animation: fadeinbg 0.25s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinbg 0.25s; /* Firefox < 16 */
  -ms-animation: fadeinbg 0.25s; /* Internet Explorer */
  -o-animation: fadeinbg 0.25s; /* Opera < 12.1 */
  animation: fadeinbg 0.25s;
  animation-fill-mode: forwards;
}

@keyframes fadeinbg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinbg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinbg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinbg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinbg {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}
