@import '../../scss/variables.scss';
@import '../../scss/styles.scss';

.new-question-modal-container {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .child-combo {
    font-size: 16px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #888;

    .title {
      font-size: 15px;
      text-align: center;
      margin-bottom: 2px;
    }
  }
  .header {
    display: flex;
    justify-content: center;

    h1 {
      margin: 7px 10px;
      font-size: 15px;
    }
  }

  .question-container {
    display: flex;
    align-items: center;

    .icon {
      font-size: 60px;
      margin-right: 15px;
      color: $brandPrimaryColor;
    }
  }

  .question {
    font-size: 17px;
    font-weight: 400;
    color: #555;
  }

  .question-input {
    height: 75px;
  }

  .notes-input {
    height: 75px;
  }

  .calendar-button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    font-size: 24px;
    color: $brandPrimaryColor;
  }

  .error-text {
    color: #c00;
  }

  .description-text {
    font-size: 13px;
    margin: 0 !important;
    font-weight: 700;
    color: #555;

    &.disabled {
      color: #aaa !important;
    }
  }

  .notes-text {
    font-size: 13px;
    margin: 0 !important;
    font-weight: 700;
    color: #555;
    margin-top: 15px !important;

    &.disabled {
      color: #aaa !important;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;

    button {
      width: 125px;
      margin-left: 15px;
    }

    .delete-button {
      width: 45px;
      margin: 0 auto 0 0;
      border: 1px solid #c00;
      color: #c00;

      &:hover {
        color: #fff;
        background-color: #c00;
      }
    }
  }
}
