@import '../../scss/variables.scss';

.navbar-lv-container {
  background: #fff;
  display: flex;
  border-bottom: 1px solid #888;
  position: fixed;
  width: 100%;
  z-index: 2;

  .logo-container {
    width: 75px;
    padding: 10px;
    margin-left: 25px;

    .logo {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .logo-container {
      width: 60px;
      padding: 10px;
    }
  }

  .navbar-links {
    display: flex;
    list-style-type: none;
    margin: 0 30px;
    padding: 0;
    align-items: center;

    .list-item {
      font-weight: 400;
      font-size: 17px;
      margin: 0 20px;
      font-family: 'Montserrat', sans-serif;

      &.selected {
        font-weight: 700;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .navbar-links {
      margin: 0 20px;

      .list-item {
        font-size: 17px;
        margin: 0 20px;
      }
    }
  }

  .settings-button {
    margin: 3px 20px 0 auto;
    align-self: center;
    background: transparent;
    border: none;
    font-size: 25px;
    color: #555;
    width: 40px;
    height: 40px;

    &:hover {
      color: $brandPrimaryColor;
    }
  }

  @media screen and (max-width: 768px) {
    .settings-button {
      margin: 3px 20px 0 auto;
      font-size: 20px;
      width: 30px;
      height: 30px;
    }
  }

  .add-entry {
    margin: 0 15px 0 0;
    align-self: center;
    font-weight: 200;
    min-width: 200px;
  }

  @media screen and (max-width: 768px) {
    .add-entry {
      margin: 0 10px 0 0;
      min-width: 150px;
      height: 37px;
    }
  }

  .settings-menu-background {
    background: transparent;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .settings-menu {
    position: absolute;
    top: 60px;
    right: 175px;
    background: #fff;
    border: 1px solid #888;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 2px 2px 10px #555;

    ul {
      list-style-type: none;
      padding: 0;
      color: $brandPrimaryColor;
      margin: 0;
    }

    a {
      color: unset;
    }

    .separator {
      border-top: 1px solid #888;
    }

    .menu-item-container {
      padding: 10px 30px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      &:hover {
        color: #fff !important;
        background: $brandPrimaryColor !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .settings-menu {
      top: 48px;
      right: 150px;
    }
  }
}
