.privacy-policy-container {
  color: #fff;
  padding: 15px;
}

strong {
  font-size: 20px;
  line-height: 75px;
}

a {
  color: #007bff !important;
  font-weight: 600;
  margin: 0 5px;
}
