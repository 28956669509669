@import '../../scss/variables.scss';

.zero-state {
  margin: 100px 0 0 0;
  color: #fff;
  text-align: center;

  p {
    margin-bottom: 50px;
    font-size: 16px;
  }

  a {
    font-size: 21px;
    margin-top: 30px;
    color: #fff;
    text-align: center;
  }

  button {
    font-weight: 200;
    padding: 0 30px;
    text-align: center;
  }
}

.home-container {
  padding: 75px 15px;
  color: #fff;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .home-container {
    padding: 60px 0;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}
