@import '../../../scss/variables.scss';

.cta-container {
  max-width: 800px;

  .get-started-link {
    font-weight: 600;
  }

  .icon-container {
    width: 200px;

    .icon {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    .icon-container {
      margin: 30px auto 0 !important;
    }
  }

  .cta-icon {
    font-size: 100px;
    color: $brandPrimaryColorLight;
  }
}
