@import '../../scss/styles.scss';

.reset-password-container {
  max-width: 400px;
  margin: auto;

  .reset-password-card {
    background: #fff;
    border: 1px solid #888;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: 15px;
  }

  .reset-password-header {
    font-size: 19px;
    align-content: center;
    align-self: center;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .submit {
    margin: 30px 0 0 0;
  }

  .cancel {
    margin: 15px 0 0 0;
  }
}

.reset-sent {
  margin-bottom: 50px;
}
