@import '../../../scss/variables.scss';

.how-it-works-container {
  max-width: 800px;

  .horizontal {
    display: flex;

    .screenshot-container {
      width: 500px;

      .screenshot-image {
        width: 100%;
      }
    }
  }

  .bullet-container {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  ul {
    margin: 0;
    padding: 0 15px;
  }

  li {
    list-style-type: none;
    font-size: 20px;
    font-weight: 200;
  }

  .bullet-icon {
    margin-right: 15px;
    font-size: 39px;
  }

  @media screen and (max-width: 576px) {
    li {
      font-size: 15px;
    }

    .bullet-icon {
      font-size: 29px;
    }
  }
}
