@import '../../scss/styles.scss';

.add-child-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 101;

  .add-child-modal {
    background: #fff;
    position: relative;
    display: flex;
    margin: auto;
    opacity: 1;
    z-index: 101;
    flex-direction: column;
    margin: 15px;
    max-width: 500px;
    padding: 30px;
    border: 1px solid #888;
    border-radius: 10px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 15px 0;
      padding: 0;

      p {
        margin: 0;
        font-size: 17px;
        padding: 0;
      }
    }

    .delete-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: #c00;
      padding: 10px 15px;
      border: 1px solid #c00;
      min-width: unset !important;
      margin: 0 32px 0 0 !important;

      &:hover {
        background: #c00;
        color: #fff;
      }
    }

    .input-container {
      display: flex;
      margin: 10px 0;
      align-items: center;

      input {
        flex-grow: 1;
      }

      .name-input {
        height: 38px;
        border-radius: 2px;
        display: flex;
        flex-grow: 1;
      }

      .birthdate-input {
        flex-grow: 1;
      }

      label {
        max-width: 80px;
        min-width: 80px;
        margin: 0;
        padding: 0;
      }

      .rw-widget-picker {
        height: 40px;
        font-size: 16px;
      }
    }

    .button-container {
      display: flex;
      margin-left: auto;
      margin-top: 30px;

      button {
        min-width: 100px;
        margin-left: 15px;
        font-weight: 200;
      }
    }

    .error-text {
      font-size: 12px;
      color: #f50057;
    }

    .avatar-button-container {
      display: flex;
      justify-content: flex-end;
      background: #fff;
    }

    .avatar-button {
      background: #fefefe;
      padding: 7px;

      &:focus {
        outline: none;
      }

      &.selected {
        border: 3px solid #333;
        padding: 5px;
      }
    }

    .avatar-button-image {
      width: 98px;
    }

    .color-button-container {
      display: flex;
      justify-content: flex-end;
    }

    .color-button {
      width: 40px;
      height: 40px;
      padding: 2px;

      &:focus {
        outline: none;
      }

      &.selected {
        border: 3px solid #333;
        padding: 0;
      }
    }
  }
}
